import React, { useEffect, useState } from "react"
import axios from "axios"
import Helmet from "react-helmet"
import isEmail from "email-format-check"

import RequiredIndicator from "../components/required_indicator"

const Plan = props => {
  const subscribeNowRef = React.createRef()

  /**
   * Local States
   */
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zip, setZip] = useState("")

  const [notInTheUS, setNotInTheUS] = useState(false)

  const [productId, setProductId] = useState("")
  const [planId, setPlanId] = useState("")
  const [cycleId, setCycleId] = useState("")

  const [addOns, setAddOns] = useState([])

  const [isFetchedError, setIsFetchedError] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  /**
   * Initial values stracture for fetched data in API
   */
  const [planDetails, setPlanDetails] = useState({
    name: "",
    displayName: "Loading Plan Name",
    description: "Loading Description",
    displayPricing: {
      price: 9700,
      priceFormatted: "$0",
      frequency: 1,
      frequencyType: "Monthly",
      setUpFeePrice: null,
      setupFeePriceFormatted: null,
    },
    addonsWithPricing: [
      {
        displayName: "Loading Add Ons",
        description: "Loading Add On Description",
        featureTags: [],
        priceModel: {
          frequencyType: "Monthly",
          currency: "USD",
          flatFeePriceFormatted: "$0",
        },
      },
    ],
  })

  /**
   * For Billsby Script Dom Scan
   */
  const scanDomBillsby = () => {
    if (typeof window.scanDomBillsby !== "undefined") window.scanDomBillsby()
  }

  /**
   *
   * @param {integer} productId
   * @param {integer} planId
   * @param {integer} cycleId
   * Used for fetching data in API
   */
  const fetchPlanAndAddOns = (productId, planId, cycleId) => {
    const url = `https://reviewvio-be.onrender.com/billsby/${productId}/${planId}/${cycleId}`
    axios
      .get(url)
      .then(({ data }) => {
        setPlanDetails(data)
      })
      .catch(err => {
        console.log(err.response.data)
        setPlanDetails({
          ...planDetails,
          displayName: err.response.data.error_code,
          description: err.response.data.error_details
            ? err.response.data.error_details
            : "",
          addonsWithPricing: [
            {
              displayName: "Error Loading Add-ons",
              description: "",
              priceModel: {
                frequencyType: "Monthly",
                flatFeePriceFormatted: "$0",
              },
            },
          ],
        })
        setIsFetchedError(true)
      })
  }

  /**
   * Runs the Billsby Scan Dom First before showing the checkout modal
   */
  const handleSubscribeNow = () => {
    if (!isFetchedError) {
      scanDomBillsby() // Run Scan to refresh data in DOM before checkout
      console.log("Scan billsby")
      subscribeNowRef.current.click() // Opens Billsby Checkout Modal Programmatically
    }
  }

  /**
   *
   * @param {boolean} checked
   * @param {integer} addOnId
   * Handles how add-on IDs are pushed and removed in addOns State.
   */
  const handleAddOns = (checked, addOnId) => {
    // IF there are no existing add-ons in the array and checked is true, push the add-on id
    if (addOns.length === 0 && checked) {
      console.log("Add:", addOnId)
      setAddOns([addOnId])
    }
    // Else
    else {
      // Map array to temp variable so that we can add / remove new values without affecting the state.
      let _addOns = addOns.map(addon => addon)

      // Find add-on ID in array
      const isExist = _addOns.find(addOn => {
        if (addOn === addOnId) return true
        else return false
      })

      // If checked is true and is existing in array
      if (checked && isExist) {
        // Do nothing because it already exist
        console.log("Already Exist")
      }
      // Else If checked is true but does not exist in array
      else if (checked && !isExist) {
        // Push id in array
        console.log("Add:", addOnId)
        _addOns.push(addOnId)

        console.log(_addOns)

        // Set array as new value of addOns state
        setAddOns(_addOns)
      }
      // Else If checked is false and it exist in the array
      else {
        // Filter array to remove id
        console.log("Remove:", addOnId)
        _addOns = _addOns.filter(item => item !== addOnId)

        // Set array as new value of addOns state
        setAddOns(_addOns)
      }
    }
  }

  /**
   * Renders the correct format for the add-ons
   * return {string} addOnsString - formatted like this: [[id],[id]]
   */
  const renderAddOns = () => {
    let addOnsString = "["
    addOns.forEach((addon, index) => {
      addOnsString += index !== addOns.length - 1 ? `[${addon}],` : `[${addon}]`
    })
    addOnsString += "]"
    return addOnsString
  }

  /**
   * At Component mount
   */
  useEffect(() => {
    if (props.pathParams !== undefined) {
      if (
        props.pathParams.productid &&
        props.pathParams.planid &&
        props.pathParams.cycleid
      ) {
        setProductId(props.pathParams.productid)
        setPlanId(props.pathParams.planid)
        setCycleId(props.pathParams.cycleid)
        scanDomBillsby()
        fetchPlanAndAddOns(
          props.pathParams.productid,
          props.pathParams.planid,
          props.pathParams.cycleid
        )
      }
    }
  }, []) // eslint-disable-line

  /**
   * Listens to the Local States used in the form and checks whether to setIsButtonDisabled to true / false
   */
  useEffect(() => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      companyName !== "" &&
      email !== "" &&
      isEmail(email)
    ) {
      if (notInTheUS) setIsButtonDisabled(false)
      else {
        if (address !== "" && city !== "" && state !== "" && zip !== "")
          setIsButtonDisabled(false)
        else setIsButtonDisabled(true)

        scanDomBillsby()
      }
    } else setIsButtonDisabled(true)
  }, [
    firstName,
    lastName,
    companyName,
    email,
    notInTheUS,
    address,
    city,
    state,
    zip,
  ])

  return (
    <div className="plan-form-wrapper">
      <Helmet>
        <script>
          {`window.billsbyData = {
            firstName: "${firstName}",
            lastName: "${lastName}",
            email: "${email}",
            billingAddressLine1: "${companyName}"
            ${
              notInTheUS
                ? ""
                : `,billingAddressLine2: "${address}",
            billingAddressCity: "${city}",
            billingAddressState: "${state}",
            billingAddressZip: "${zip}",
            billingAddressCountry: "${notInTheUS === false ? "USA" : ""}",`
            }
            };`}
        </script>
      </Helmet>
      <div className="content-wrapper">
        <div className="section">
          <div className="title-wrapper">
            <p className="number">1</p>
            <p className="title">Your Plan</p>
          </div>
          <div className="plan-display">
            <p className="text">{planDetails.displayName}</p>
            <p className="text-sm">
              {planDetails.displayPricing.priceFormatted}{" "}
              {planDetails.displayPricing.frequencyType.toLowerCase()}
              {planDetails.displayPricing.setupFeePriceFormatted !== null
                ? ` + ${planDetails.displayPricing.setupFeePriceFormatted} activation fee`
                : ""}
            </p>
          </div>
          <p className="text-sm">{planDetails.description}</p>
        </div>
        <div className="section">
          <div className="title-wrapper">
            <p className="number">2</p>
            <p className="title">Your Optional Extras</p>
          </div>

          {planDetails.addonsWithPricing.map(addon => (
            <label className="custom-radio" key={addon.displayName}>
              <p className="text">{addon.displayName}</p>
              <p className="text-sm">
                {addon.priceModel.flatFeePriceFormatted} per{" "}
                {addon.priceModel.frequencyType
                  .toLowerCase()
                  .substring(0, addon.priceModel.frequencyType.length - 2)}
              </p>
              <p className="text-sm description">{addon.description}</p>
              <input
                type="checkbox"
                name={addon.displayName}
                value={addon.displayName}
                onChange={e => {
                  handleAddOns(e.target.checked, addon.addonId)
                }}
              />
              <span className="checkmark"></span>
            </label>
          ))}
        </div>
        <div className="section">
          <div className="title-wrapper">
            <p className="number">3</p>
            <p className="title">Your Details</p>
          </div>
          <div className="custom-input-wrapper two-col">
            <label className="custom-input">
              <p className="text-sm">
                First Name <RequiredIndicator />
              </p>
              <input
                type="text"
                name="firstName"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </label>
            <label className="custom-input">
              <p className="text-sm">
                Last Name <RequiredIndicator />
              </p>
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </label>
          </div>
          <div className="custom-input-wrapper">
            <label className="custom-input">
              <p className="text-sm">
                Email <RequiredIndicator />
              </p>
              <input
                type="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </label>
          </div>
          <div className="custom-input-wrapper">
            <label className="custom-input">
              <p className="text-sm">
                Company Name <RequiredIndicator />
              </p>
              <input
                type="text"
                name="companyName"
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
              />
            </label>
          </div>
          {notInTheUS ? (
            ""
          ) : (
            <>
              <div className="custom-input-wrapper">
                <label className="custom-input">
                  <p className="text-sm">
                    Address <RequiredIndicator />
                  </p>
                  <input
                    type="text"
                    name="address"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                </label>
              </div>
              <div className="custom-input-wrapper three-col">
                <label className="custom-input">
                  <p className="text-sm">
                    City <RequiredIndicator />
                  </p>
                  <input
                    type="text"
                    name="city"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
                </label>
                <label className="custom-input">
                  <p className="text-sm">
                    State <RequiredIndicator />
                  </p>
                  <input
                    type="text"
                    name="state"
                    value={state}
                    onChange={e => setState(e.target.value)}
                  />
                </label>
                <label className="custom-input">
                  <p className="text-sm">
                    ZIP <RequiredIndicator />
                  </p>
                  <input
                    type="number"
                    name="zip"
                    value={zip}
                    onChange={e => setZip(e.target.value)}
                  />
                </label>
              </div>
            </>
          )}
        </div>

        {!notInTheUS && (
          <span
            className="link"
            onClick={() => setNotInTheUS(true)}
            aria-hidden="true"
          >
            Not in the US
          </span>
        )}

        {notInTheUS && (
          <span
            className="link"
            onClick={() => setNotInTheUS(false)}
            aria-hidden="true"
          >
            In the US
          </span>
        )}

        <button
          className={`btn-green`}
          onClick={handleSubscribeNow}
          {...(isButtonDisabled || isFetchedError ? { disabled: true } : "")}
        >
          Subscribe now
        </button>
        <button
          // href="javascript:void(0)"
          data-billsby-type="checkout"
          data-billsby-product={productId}
          data-billsby-plan={planId}
          data-billsby-cycle={cycleId}
          data-billsby-addons={addOns.length > 0 ? renderAddOns() : `[[]]`}
          ref={subscribeNowRef}
          style={{ display: "none" }}
          aria-label="Billsby Hidden Button"
        />
      </div>
    </div>
  )
}

export default Plan
